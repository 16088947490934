import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Button, Grid, Typography, Card, CardContent, Container, AppBar, Tabs, Tab, Toolbar, Box, Menu, MenuItem } from '@mui/material';
import AboutUs from './AboutUs'; 
import ContactUs from './ContactUs'; 
import Footer from './Footer'; 
import GettingStarted from './GettingStarted'; 
import Documentation from './Documentation'; 
import Blog from './Blog';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import TryDemo from './TryDemo';
import './App.css'; 
import bgImage from './bg-image.webp'; // Add this import

ReactGA.initialize('G-ED2XJBWG65');

const iconStyle = {
  color: 'black',
  transition: 'color 0.3s',
  fontSize: '2rem', // Increase icon size
};

const iconHoverStyle = {
  color: 'grey',
  fontSize: '2rem', // Increase icon size
};

const cardStyle = {
  borderRadius: '15px',
  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)'
};

// Add PageTracker component
const PageTracker = ({ children }) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return children;
};

const App = () => {
  const [visible, setVisible] = React.useState(true);
  const [prevScrollPos, setPrevScrollPos] = React.useState(0);
  const [isHome, setIsHome] = React.useState(true);

  // Create a component to handle route changes
  const RouteListener = () => {
    const location = useLocation();
    
    useEffect(() => {
      setIsHome(location.pathname === '/');
    }, [location]);
    
    return null;
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible = prevScrollPos > currentScrollPos || currentScrollPos < 100;

      setPrevScrollPos(currentScrollPos);
      setVisible(isVisible);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleResourcesClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleResourcesClose = () => {
    setAnchorEl(null);
  };

  const handleContactClick = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Click',
      label: 'Contact Us'
    });
  };

  return (
    <Router>
      <RouteListener />
      <div>
        <AppBar position="fixed" style={{ 
          background: 'transparent', 
          boxShadow: 'none',
          transform: visible ? 'translateY(0)' : 'translateY(-100%)',
          transition: 'transform 0.3s ease-in-out'
        }}>
          <Toolbar>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/*<img src={require('./logo.webp')} alt="Logo" style={{ height: '40px', marginRight: '1em' }} />*/}
              <Typography 
                variant="h4" 
                style={{ textDecoration: 'none', color: isHome ? 'white' : 'var(--primary-color)' }} 
                component={Link} 
                to="/"
              >
                Astraegis
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              <Tabs value={0} centered style={{ boxShadow: 'none' }} TabIndicatorProps={{ style: { display: 'none' } }}>
                <Tab label="About" component={Link} to="/about" style={{ color: isHome ? 'white' : 'var(--primary-color)' }} />
                <Tab label="Resources" style={{ color: isHome ? 'white' : 'var(--primary-color)' }} onClick={handleResourcesClick} />
                {/*<Tab label="Blog" component={Link} to="/blog" style={{ color: 'black' }} />*/}
                <Tab 
                  label="Contact" 
                  component={Link} 
                  to="/contact" 
                  style={{ color: isHome ? 'white' : 'var(--primary-color)' }} 
                  onClick={handleContactClick}
                />
              </Tabs>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleResourcesClose}
              >
                <MenuItem component={Link} to="/getting-started" onClick={handleResourcesClose}>Getting Started</MenuItem>
                <MenuItem component={Link} to="/documentation" onClick={handleResourcesClose}>Documentation</MenuItem>
              </Menu>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                variant="contained" 
                className={isHome ? "primary-button" : "secondary-button"}
                component={Link} 
                to="/try-demo"
              >
                Try Demo
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Routes>
          <Route path="/about" element={<PageTracker><AboutUs /></PageTracker>} />
          <Route path="/contact" element={<PageTracker><ContactUs /></PageTracker>} />
          <Route path="/" element={
            <PageTracker>
              {/* Hero Section */}
              <section style={{ 
                //backgroundImage: `url(${bgImage})`, // Fix the background image syntax
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'var(--primary-color)', 
                padding: '0', 
                height:'100vh', 
                display: 'flex', 
                alignItems: 'center' 
              }}>
                <Container>
                  <Grid className='hero-section' container spacing={3} alignItems="center" style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                    <Grid item xs={12} md={6} >
                      <Typography className="hero-text" variant="h3" gutterBottom >
                        Protect your AI Agents from going rogue.
                      </Typography>
                      <Typography className="hero-text" variant="subtitle1" paragraph >
                        Restrict access to sensitive data and resources with our highly scalable advanced middleware tailored to your business needs.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <img src={require('./main.png')} alt="Hero" style={{ width: '100%', height: 'auto' }} />
                      </Grid>
                  </Grid>
                </Container>
              </section>

              {/* Features Section */}
              <section className='sectionStyle' style={{ backgroundColor: 'var(--secondary-bg-color)', height:'100vh', display: 'flex', alignItems: 'center' }}>
                <Container>
                  <Grid container spacing={4} style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                    <Grid item xs={12} md={4}>
                      <Card style={cardStyle}>
                        <CardContent>
                          <Typography variant="h4">AI to monitor AI </Typography>
                          <Typography variant="body6" style={{ fontSize: '1rem' }}>Leverage AI to enhance your security measures and protect your data. Protect your models from prompt injection attacks</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Card style={cardStyle}>
                        <CardContent>
                          <Typography variant="h4">Access Control</Typography>
                          <Typography variant="body6" style={{ fontSize: '1rem' }}>Manage and seamlessly restrict your AI systems from accessing restricted resources.</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Card style={cardStyle}>
                        <CardContent>
                          <Typography variant="h4">Data Masking</Typography>
                          <Typography variant="body6" style={{ fontSize: '1rem' }}>Ensure your sensitive data is safe and your PII remains within your organization with our comprehensive protection solutions.</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Container>
              </section>


              <Container>
              <Typography  mt={20} variant="h2" align="center" justifyContent='center'>
                          Stay Compliant and keep your PII data secure
              </Typography>
              <Typography  mb={20} variant="h6" align="center" justifyContent='center'>
                          Create outbound and inbound rules to protect your Sensitive data
              </Typography>
              <Grid item xs={12} md={6} mb={10} >
                      <img src={require('./screenshot3.png')} alt="Why Choose Us" style={{ borderRadius:'1.5vh', width: '100%', height: 'auto',  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }} />
              </Grid>
              </Container>
              
              {/* Why Choose Us Section */}
              <section style={{ padding: '5em 0',  height:'100vh', backgroundColor: '#000', display: 'flex', alignItems: 'center' , }}>
                <Container>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h4" gutterBottom style={{ color: '#fff' }}>
                        Why Choose Us?
                      </Typography>
                      <Typography variant="h6" gutterBottom style={{ color: '#fff' }}>
                        Because its fast!
                      </Typography>
                      <Typography variant="body1" paragraph style={{ color: '#fff'}}>
                        Our platform offers unparalleled security features that ensure your data is always protected. With our advanced AI monitoring and access control, you can rest assured that your sensitive information is safe.
                      </Typography>
                      <Typography variant="body1" paragraph style={{ color: '#fff' }}>
                        Join the many businesses that trust us to safeguard their data and provide them with the tools they need to manage their AI systems effectively.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <Card style={{ borderRadius: '15px', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#311B92',  boxShadow: '0 8px 16px rgba(255, 255, 255, 0.2)' }}>
                            <CardContent>
                              <Typography variant="h5" style={{ color: '#fff' }}>Competitive Pricing</Typography>
                              <Typography variant="body2" style={{ color: '#fff' }}>We offer competitive pricing plans that fit businesses of all sizes. Our flexible pricing ensures you get the best value for your investment.</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card style={{ borderRadius: '15px', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#4A148C', boxShadow: '0 8px 16px rgba(255, 255, 255, 0.2)' }}>
                            <CardContent>
                              <Typography variant="h5" style={{ color: '#fff' }}>Custom Integrations</Typography>
                              <Typography variant="body2" style={{ color: '#fff' }}>Our platform supports custom integrations to meet your specific needs. We work closely with you to ensure seamless integration with your existing systems.</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card style={{ borderRadius: '15px', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#4A148C' , boxShadow: '0 8px 16px rgba(255, 255, 255, 0.2)' }}>
                            <CardContent>
                              <Typography variant="h5" style={{ color: '#fff' }}>Highly Scalable</Typography>
                              <Typography variant="body2" style={{ color: '#fff' }}>Our architecture is highly scalable to grow with your business. Whether you're a small startup or a large enterprise, our platform can handle your needs.</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card style={{ borderRadius: '15px', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#311B92', boxShadow: '0 8px 16px rgba(255, 255, 255, 0.2)' }}>
                            <CardContent>
                              <Typography variant="h5" style={{ color: '#fff' }}>24/7 Support</Typography>
                              <Typography variant="body2" style={{ color: '#fff' }}>We provide 24/7 support to ensure your operations run smoothly. Our dedicated support team is always available to assist you with any issues.</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </section>


              {/* Our Mission */}
              <section style={{ padding: '5em 0',  height:'100vh', backgroundColor: '#e0e0e0', display: 'flex', alignItems: 'center' }}>
                <Container>
                  <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={6}>
                      <Typography variant="h4" gutterBottom>
                        Our Mission
                      </Typography>
                      <Typography variant="body5" paragraph>
                        At Astraegis, our mission is to provide top-notch AI security solutions that empower businesses to protect their data and control access to their resources. We are committed to innovation and excellence in AI security.
                      </Typography>
                      <Typography variant="body5" paragraph>
                        We believe in a future where AI can be used safely and responsibly, and we are dedicated to making that future a reality.
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
              </section>

              {/* CTA Section */}
              <section style={{ padding: '5em 0',  height:'100vh', display: 'flex', alignItems: 'center', background: 'linear-gradient(to right, #ff7e5f, #feb47b)' }}>
                <Container>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Typography variant="h4" gutterBottom>
                        Take the next step
                      </Typography>
                      <Typography variant="h2" gutterBottom>
                        Ready when you are.
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Sign up for our platform and start protecting your data today.
                      </Typography>
                      <Button variant="contained" className="secondary-button" component={Link} to="/getting-started">
                        Get Started
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <img src={require('./cta.png')} alt="CTA" style={{ width: '100%', height: 'auto' }} />
                    </Grid>
                  </Grid>
                </Container>
              </section>

              {/* Footer Section */}
              <Footer />
            </PageTracker>
          } />
          <Route path="/getting-started" element={<PageTracker><GettingStarted /></PageTracker>} />
          <Route path="/documentation" element={<PageTracker><Documentation /></PageTracker>} />
          <Route path="/blog" element={<PageTracker><Blog /></PageTracker>} />
          <Route path="/privacypolicy" element={<PageTracker><PrivacyPolicy /></PageTracker>} />
          <Route path="/termsofservice" element={<PageTracker><TermsOfService /></PageTracker>} />
          <Route path="/try-demo" element={<PageTracker><TryDemo /></PageTracker>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
